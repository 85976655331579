// JS dependencies
var bootstrapselect = require('bootstrap-select');

// CSS dependency
import '/../node_modules/bootstrap-select/dist/css/bootstrap-select.css';

// Initialisation
$('.selectpicker').selectpicker({
  container: 'body'
});

$('#resa-place-start').on('change', function() {
  var selectdItem = $('#resa-place-start').val();
  $('#resa-place-end').val(selectdItem);
  $('#resa-place-end').selectpicker('refresh');
});


$('.selectpicker').on('changed.bs.select', function(e){
  $(this).parent().addClass('changed');
});
