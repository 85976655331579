// Radiopicker switch
$('.btn-switch-wp .label').on('click', function() {
  $(this).prev().find('.checkbox-toggle').trigger('click');
});

function f_resa_options() {
    $('#options input[type=checkbox]').on('change', function () {
        checkbox($(this));
        request();
    });

    $('#options .quantite select').on('change', function () {
        $(this).parents('.item').find('input[type=checkbox]').prop('checked', $(this).val() !== '0');
        request();
    });

    $('#options .quantite').on('click', function () {
        if ($(this).hasClass('disabled')) {
            $(this).parent().find('label').trigger('click');
            request();
        }
    });

    // Initialisation
    request();
    $('#options input[type=checkbox]').each(function () {
        checkbox($(this));
    });

    function checkbox(self) {
        var select = self.parent().parent().find('select');
        var $quantite = select.parent().parent().parent();

        if (self.is(":checked")) {
            $quantite.removeClass('disabled');
            $quantite.find('select').val(1).change();
        } else {
            select.parent().parent().parent().addClass('disabled');
            $quantite.find('select').val(0).change();
        }
    }

    function request() {
        $.ajax({
            method: "POST",
            url: $("#options").data('ajax-url'),
            data: $("#devis").serialize(),
            success: function(data) {
                $("._aside").html(data.aside);
                $("#_total").html(data.total);
            }
        });
    }

}

if ($('#options').length > 0) {
    f_resa_options();
}

function f_resa_condition() {
    $('#conditions').on('change', function () {
        request();
    });

    // Initialisation
    request();

    function request() {

        $('.openinfo', '#conditions').hide();
        $('#openinfo-' + $('input[name=condition]:checked', '#conditions').val()).show();

        $.ajax({
            method: "POST",
            url: $("#conditions").data('ajax-url') + '/' + $('input[name=condition]:checked', '#conditions').val(),
            data: { _token: $("#conditions").data('csrf') },
            success: function(data) {
                $("#_bouton_payer").html(data.bouton_payer);
                $("._aside").html(data.aside);
                $("#_total").html(data.total);
            }
        });
    }

}

if ($('#conditions').length > 0) {
    f_resa_condition();
}



function f_Carrosserie(){
    $("#carrosserie").on('change', function() {
        if ($(this).val() !== '') {
            const selectedCarrosserie = $(this).val();
            $('.resa-car').hide();
            $('.resa-car').each(function() {
                const carrosseries = $(this).attr('data-carrosserie').split(',');
                if( carrosseries.includes(selectedCarrosserie) ) {
                    $(this).show();
                }
            });
        } else {
            $('.resa-car').show();
        }

        if ($(this).val()) {
            $('#carrosserie-nom').text($('option:selected', this).text());
        } else {
            $('#carrosserie-nom').text('');
        }
        AOS.refresh();
        //$('#search-count').text($('.resa-step-1:visible:not(.unavailable)').length);
    });
    $("#carrosserie").trigger('change');
}
f_Carrosserie();
