// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '/../node_modules/slick-carousel/slick/slick.css';

// Example
$('#slick-home').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  fade: true,
  arrows: false,
  autoplay: true,
  adaptiveHeight: false,
  cssEase: 'ease-in-out',
  speed: 200,
  autoplaySpeed: 1500,
  responsive: [{
      breakpoint: 1200,
      settings: {}
    },
    {
      breakpoint: 991,
      settings: {}
    },
    {
      breakpoint: 576,
      settings: {}
    },
  ]
});
